import { observable, makeObservable , action, computed } from "mobx";
import visa from '../assets/images/visa.svg';
import mastercard from '../assets/images/mastercard.svg';
import amex from '../assets/images/amex.svg';
import discover from '../assets/images/discover.svg';
import bank from '../assets/images/bank.svg';
import jcb from '../assets/images/jcb.svg';
import device from '../assets/images/device.svg';

class GlobalStore {

    constructor() {
        makeObservable(this)
    }

    @observable
    loading = false;
    
    @observable
    ready = false;

    @observable
    rightPanelOpen = false;

   
    entryPoint = "entry";

    @action
    numberWithCommas(x) {
        var numString = x.toString().replace(/.(?=(?:.{3})+$)/g, '$&,');
        return numString.replace(',.', '.');
    }

    newTabRedirection(url, e){
        if(e){
            e.preventDefault();
        }
        window.open(url, '_blank').focus();
    }

    frequencyText(frequency){
        switch(frequency){
            case "onetime":
                return "Onetime";
            case "weekly":
                return "Weekly";
            case "every2weeks":
                return "Every 2 Weeks";
            case "every6months":
                return "Every 6 Months";
            case "monthly":
                return "Monthly";
            case "every3months":
                return "Every 3 months";
            case "annually":
                return "Annually";
            default:
                return "Unknow";

        }
    }

    createPasswordLabel(result){
        switch (result) {
          case 0:
            return 'Weak';
          case 1:
            return 'Weak';
          case 2:
            return 'Fair';
          case 3:
            return 'Good';
          case 4:
            return 'Strong';
          default:
            return 'Weak';
        }
      }

      luhnCheck(ccNum) {
        let arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
        let 
            len = ccNum.length,
            bit = 1,
            sum = 0,
            val;

        while (len) {
            val = parseInt(ccNum.charAt(--len), 10);
            bit ^= 1; // Perform the bitwise XOR operation separately
            sum += bit ? arr[val] : val;
        }

        return sum && sum % 10 === 0;
    };

    isValidRouting(routing){
        if (routing.length !== 9) {
            return false;
        }
        let checksumTotal = (3 * (parseInt(routing.charAt(0),10))) + (7 * (parseInt(routing.charAt(1),10))) + (1 * (parseInt(routing.charAt(2),10))) +
                            (3 * (parseInt(routing.charAt(3),10))) + (7 * (parseInt(routing.charAt(4),10))) + (1 * (parseInt(routing.charAt(5),10))) +
                            (3 * (parseInt(routing.charAt(6),10))) + (7 * (parseInt(routing.charAt(7),10))) + (1 * (parseInt(routing.charAt(8),10)));
        return (checksumTotal % 10 === 0);
    }

    creditCardType(number) {
        if (!number || number === '') {
            return 'unknown';
        }
        
        if(number.length > 14 ){
            if(this.luhnCheck(number) === false){
                return 'unknown';
            }
        }

        var creditCardType = require("credit-card-type");
        var visaCards = creditCardType(number);
        if(visaCards[0]){
            return visaCards[0].type;
        }
        else{
            return 'unknown';
        }
    }

    getPaymethodImg(method){
        if(!method){
            return <img title="Checking" style={{height: "19px"}} alt="" src={bank} />;
        }
        switch(method.toLowerCase()){
            case "visa":
                return <img title="Visa" style={{height: "11px"}} alt="" src={visa} /> ;
            case "mastercard":
                return <img title="MasterCard" style={{height: "17px"}} alt="" src={mastercard} />;
            case "discover":
                return <img title="Discover" style={{height: "14px"}} alt="" src={discover} />;
            case "jcb":
                return <img title="JCB" style={{height: "17px"}} alt="" src={jcb} />;
            case "amex":
            case "american-express":
                return <img title="American Express" style={{height: "17px"}} alt="" src={amex} />;
            case "device":
                return <img title="Device" style={{height: "17px"}} alt="" src={device} />;
            default:
                return <img title="Checking" style={{height: "19px"}} alt="" src={bank} />;
        }
    }

    protect(history){
        if(!localStorage.getItem('pToken')){
            history.push("/login");
        }
    }

    stringDateFormat(string){
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var date = new Date(string);
        return( monthNames[date.getMonth()] + ' ' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + ', ' + date.getFullYear());
    }

    stringDateFormatV2(string){
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        string = string.split("-");
        return monthNames[parseInt(string[1]) - 1] + " "+ string[0];
    }

    getMonths(){
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }

    expDateFormat(string){
        if(string.length === 3){
            return string[0]+"/"+string[string.length-2]+string[string.length-1]
        }else if(string.length === 4){
            return string[0]+string[1]+"/"+string[string.length-2]+string[string.length-1]
        } 
    }

    stringDateFormatFilters(string){
        let date = new Date(string);
        return( date.getFullYear() + '-' + ( date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1 ) +'-'+((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())));
    }

    stringTimeFormat(string){
        var date = new Date(string);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    capitalizeFirstLetter(string) {
        string = string.toLowerCase();
        return string[0].toUpperCase() + string.slice(1);
    }

    maskedCardNumber(string, version){
        if(!string){
            return string;
        }

        switch(version){
            case "v1":
                return string.replace("XXXXXX", "xxx xxxx xxxx ");
            case "v2":
                return string.replaceAll("X", "x");
            case "v3":
                return "**** **** ****  " + string.substr(string.length - 4); 
            case "v4":
                return string.replace("XXXXXXXX", "xxx xxxx xxxx ");
            case "v5":
                return "****  " + string.substr(string.length - 4);
            case "v6":
                return `∙∙∙∙ ${string.substr(string.length - 4)}`; 
            default:
                return string;
        }
    }

    maskValidator(validation){

        switch(validation) {
            case 'alpha':
                return /^[a-zA-Z\s]*$/;
            case 'numbers':
                return Number;
            case 'alphanumeric':
                return /^[a-z0-9]+$/i;
            case 'alphanumericspaces':
                return /^[a-zA-Z0-9 ]+$/i;
            case 'alphanumericspaceslatin':
                return /^[a-zA-Z0-9 áÁéÉíÍóÓúÚüÜñÑ]+$/i;
            case 'text':
                return String;
            case 'email':
                return  /^([a-zA-Z0-9][-._]?)+@?([a-zA-Z0-9][-_]?)*(\.)?([a-zA-Z]*)$/;
            case 'phone':
                return '(000) 000-0000';
            case 'routing':
                return '000000000';
            case 'accountnumber':
                return /^[0-9]+$/i;
            case 'cardDate':
                return '00/00';
            case 'cvv':
                return '000';
            case 'cvvamex':
                return '0000';
            case 'zipcode':
                return '00000';
            case 'creditCard':
                return '0000 0000 0000 0000';
            case 'creditCardAmex':
                return '0000 000000 00000';
            case 'creditCardDiners14':
                return '0000 000000 00000';
            case 'creditCardDiners16':
                return '0000 0000 0000 0000';
            default:
                return String;
          }

    }

    removeAccents(text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    validators = {
        isEmpty : function(value){
            if(value === '' || value === null || value === undefined)
            {
                return true;
            }
            else{
                return false;
            }
        },

        isMaxLength : function(length, value){
            if(value){
                value = value.replace(/ /g, '');
                if(value.length > length)
                {
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return false;
            }
            
        },

        isMinLength : function(length, value){
            if(value){
                value = value.replace(/ /g, '');
                if(value.length >= length)
                {
                    return false;
                }
                else{
                    return true;
                }
            }
            else{
                return false;
            }
            
        },

        isLength : function(length, value){
            if(value){
                value = value.replace(/ /g, ''); 
                if(value.length === length)
                {
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return false;
            }
            
        },

        stringValidator: function(validation, value){
            if(!value){
                return false;
            }
            switch(validation) {
                case 'alpha':
                    return !/^[a-zA-Z\s]*$/.test(value);
                case 'alphanumericspaces':
                    return !/^[a-zA-Z0-9 ]*$/.test(value);
                case 'alphanumericspaceslatin':
                    return !/^[a-zA-Z0-9 áéíóúÁÉÍÓÚñÑ]*$/.test(value);
                case 'numbers':
                    return !/^[0-9]*$/.test(value);
                case 'text':
                    return false;
                case 'email':
                    return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value.toLowerCase());
                case 'phone':
                    return !/^([0-9]{10,10})$/.test(value);
                case 'card':
                    value = value.replace(/ /g, '');
                    return !/^([0-9]{14,16})$/.test(value);
                case 'routing':
                    return !/^([0-9]{9,9})$/.test(value);
                case 'exp':
                    value = value.replace("/", '');
                    return !/^([0-9]{4,4})$/.test(value);
                case 'cvvamex':
                    return !/^([0-9]{4,4})$/.test(value);
                case 'cvv':
                    return !/^([0-9]{3,3})$/.test(value);
                case 'zipcode':
                    return !/^([0-9]{5,5})$/.test(value);
                case 'zipcodeus':
                    return !/^([0-9]{5,5})$/.test(value);
                case 'zipcodeca':
                    return !/^([a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z][0-9])$/.test(value);
                default:
                    return String;
              }
        }
    }

    checkZipFormat(array, value){
        let isValid = false;
        let validators = this.validators;
        array.forEach(function (item) {
            if(validators.stringValidator('zipcode'+ item.toLowerCase(), value) === false){
                isValid = true;
            }
        });
        return isValid;
    }

    getTransStatus(status){
        switch(status){
            case 1:
                return <span className="badge rounded-pill bg-success">Success</span>;
            case 15:
                return <span className="badge rounded-pill bg-light text-dark">Refunded</span>;
            case 5:
                return <span className="badge rounded-pill bg-warning">Voided</span>;
            case 12:
                return <span className="badge rounded-pill bg-light text-dark">Returned</span>;
            case 11:
                return <span className="badge rounded-pill bg-dark">Uncaptured</span>;
            case 3:
                return <span className="badge rounded-pill bg-danger">Failed</span>;
            case 2:
                return <span className="badge rounded-pill bg-danger">Declined</span>;
            case -1:
                return <span className="badge rounded-pill bg-secondary">Error</span>;
            default:
                return <span className="badge rounded-pill bg-secondary">Error</span>;

        }
    }

    getTransStatusText(status){
        switch(status){
            case 1:
                return <span className="green-text">Success</span>;
            case 15:
                return <span className="">Refunded</span>;
            case 5:
                return <span className="">Voided</span>;
            case 12:
                return <span className="">Returned</span>;
            case 11:
                return <span className="">Uncaptured</span>;
            case 3:
                return <span className="red-text">Failed</span>;
            case 2:
                return <span className="red-text">Declined</span>;
            case -1:
                return <span className="red-text">Error</span>;
            default:
                return <span className="red-text">Error</span>;

        }
    }

    getZipcodeMaxLength(array){
        let maxLength = 0;
        let lengthValues = {
            us : 5,
            ca : 6
        }
        array.forEach(function (item) {
            if(lengthValues[item.toLowerCase()] && lengthValues[item.toLowerCase()] > maxLength){
                maxLength = lengthValues[item.toLowerCase()];
            }
        });
        return maxLength;
    }

    getCardFullName(type){
        switch (type.toLowerCase()) {
          case 'visa':
            return 'Visa';
          case 'amex':
            return 'American Express';
          case 'mastercard':
            return 'Mastercard';
          case 'discover':
            return 'Discover';
          case 'jcb':
            return 'JCB';
          case 'diners':
            return 'Diners Club';
          default:
            return 'Unknown';
        }
      }

    @action
    setRightPanelOpen(isOpen){
        this.rightPanelOpen = isOpen;
    }
   
    @action
    setLoading(status){
        this.loading = status;
    }
    
    @action
    setReady(status){
        this.ready = status;
    }

    @computed
    get isLoading(){
        return this.loading;
    }
    
    @computed
    get isReady(){
        return this.ready;
    }
    
    
}

const globalStore = new GlobalStore();
export default globalStore;